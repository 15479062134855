import BankID from '@/api/BankID'

var bankIDClient = null

const availStates = {
  START: 1,
  PROVIDE_PERSONNUMBER: 2,
  PENDING: 3,
  PENDING_SHOW_QR: 4,
  FAILED: 5,
  SUCCESS: 6
}

const getDefaultState = () => {
  return {
    availStates: availStates,
    authState: availStates.START,
    personNumber: null,
    orderRef: null,
    autoStart: false,
    autoStartToken: null,
    autoStartTokenUrl: null,
    autoStartTokenQrImage: null,
    isMobile: false,
    message: 'Identifiering eller underskrift pågår',
    collector: null,
    qrCodeCollector: null,
    method: null,
    debug: false,
    collectInterval: 2000,
    session:
      typeof sessionStorage !== 'undefined'
        ? sessionStorage.getItem('session')
        : null
  }
}

const state = getDefaultState()

export default {
  state,
  mutations: {
    setSession (state, session) {
      state.session = session
    },
    setState (state, authState) {
      state.authState = authState
    },
    setMethod (state, method) {
      state.method = method
    },
    setMessage (state, message) {
      state.message = message
    },
    setOrderRef (state, orderRef) {
      state.orderRef = orderRef
    },
    setAutoStart (state, autoStart) {
      state.autoStart = autoStart
    },
    setAutoStartToken (state, autoStartToken) {
      state.autoStartToken = autoStartToken
    },
    setAutoStartTokenUrl (state, autoStartTokenUrl) {
      state.autoStartTokenUrl = autoStartTokenUrl
    },
    setAutoStartTokenQrImage (state, autoStartTokenQrImage) {
      state.autoStartTokenQrImage = autoStartTokenQrImage
    },
    setCollectInterval (state, collectInterval) {
      state.collectInterval = collectInterval
    },
    setCollector (state, collector) {
      state.collector = collector
    },
    setQrCodeCollector (state, qrCodeCollector) {
      state.qrCodeCollector = qrCodeCollector
    },
    setIsMobile (state, isMobile) {
      state.isMobile = isMobile
    },
    setDebug (state, debug) {
      state.debug = debug
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    startCollector ({ commit, dispatch }) {
      if (this.state.session.collector !== null) {
        throw new Error('collector is already running')
      }
      const self = this
      commit(
        'setCollector',
        setInterval(function () {
          bankIDClient
            .collect({
              orderRef: self.state.session.orderRef,
              device: self.state.session.isMobile ? 'mobile' : 'computer',
              method: self.state.session.method
            })
            .then((r) => {
              if (r.status === 200) {
                switch (r.data.collectStatus) {
                  case 'complete':
                    dispatch('stopCollector')
                    commit('setMessage', 'SUCCESS')
                    commit('setState', availStates.SUCCESS)
                    var s = JSON.stringify({
                      accessToken: r.data.token,
                      tokenExpires: r.data.tokenExpires,
                      account: r.data.bankidAccount
                    })
                    sessionStorage.setItem('session', s)
                    commit('setSession', s)
                    break
                  case 'pending':
                    if (r.data.userMessage) {
                      commit('setMessage', r.data.userMessage.Swedish)
                    }
                    break
                  case 'failed':
                  default:
                    dispatch('stopCollector')
                    if (r.data.userMessage) {
                      commit('setMessage', r.data.userMessage.Swedish)
                    }
                    commit('setState', availStates.FAILED)
                    break
                }
              } else {
                dispatch('stopCollector')
                commit('setMessage', availStates.FAILED)
                commit('setState', availStates.FAILED)
              }
            })
            .catch(() => {
              dispatch('stopCollector')
              commit('setMessage', 'Unknown error. Please try again.')
              commit('setState', availStates.FAILED)
            })
        }, self.state.session.collectInterval)
      )
    },
    stopCollector () {
      if (this.state.session.collector === null) {
        throw new Error('collector is not running')
      }
      clearInterval(this.state.session.collector)
      this.state.session.collector = null
    },
    startQrCodeCollector ({ commit, dispatch }) {
      if (this.state.session.collector !== null) {
        throw new Error('collector is already running')
      }
      const self = this
      commit(
        'setQrCodeCollector',
        setInterval(function () {
          if (
            self.state.session.authState === availStates.FAILED ||
            self.state.session.authState === availStates.SUCCESS
          ) {
            dispatch('stopQrCodeCollector')
            commit('setAutoStartTokenQrImage', null)
            return
          }

          bankIDClient.qrcode(self.state.session.orderRef).then((r) => {
            if (r.status === 200) {
              commit('setAutoStartTokenQrImage', r.data)
            }
          })
        }, 1000)
      )
    },
    stopQrCodeCollector () {
      if (this.state.session.qrCodeCollector === null) {
        throw new Error('qrCodeCollector is not running')
      }
      clearInterval(this.state.session.qrCodeCollector)
      this.state.session.qrCodeCollector = null
    },
    startAuth ({ commit, dispatch }, data) {
      bankIDClient = new BankID(this.state.session.mobile)
      commit('setAutoStart', data.thisDevice)
      commit('setAutoStartToken', null)
      commit('setAutoStartTokenQrImage', null)
      commit('setAutoStartTokenUrl', null)
      commit('setCollectInterval', data.collectInterval)
      commit('setIsMobile', data.mobile)
      commit('setDebug', data.debug)

      switch (true) {
        case data.thisDevice:
          commit('setMethod', 'auto')
          break
        case data.personNumber === null:
          commit('setMethod', 'qr')
          break
        default:
          commit('setMethod', 'manual')
          break
      }
      commit(
        'setState',
        this.state.session.method === 'qr'
          ? availStates.PENDING_SHOW_QR
          : availStates.PENDING
      )

      bankIDClient
        .auth({
          personalNumber: data.personNumber,
          autoStart: this.state.session.autoStart,
          device: this.state.session.isMobile ? 'mobile' : 'computer',
          method: this.state.session.method
        })
        .then((r) => {
          if (r.status !== 200) {
            commit('setMessage', 'Unknown error. Please try again.')
            commit('setState', availStates.FAILED)
            return
          }
          commit('setOrderRef', r.data.orderRef)
          if (this.state.session.method === 'qr') {
            dispatch('startQrCodeCollector')
          } else if (this.state.session.method === 'auto') {
            commit('setAutoStartToken', r.data.autoStartToken)
            var url =
              'bankid:///?autostarttoken=' +
              this.state.session.autoStartToken +
              '&redirect=null'
            if (navigator.platform.match(/iPhone|iPod|iPad/)) {
              url =
                'https://app.bankid.com/?autostarttoken=' +
                this.state.session.autoStartToken +
                '&redirect=null'
            }

            commit('setAutoStartTokenUrl', url)
          } else if (this.state.session.method === 'manual') {
            // Manual
          }
          dispatch('startCollector')
        })
        .catch(() => {
          commit('setMessage', 'Unknown error. Please try again.')
          commit('setState', availStates.FAILED)
        })
    },
    refreshToken ({ commit, state }) {
      if (!bankIDClient) {
        bankIDClient = new BankID(true)
      }

      var s = JSON.parse(state.session)

      return bankIDClient.refresh(s.accessToken).then((r) => {
        if (r && r.data) {
          s.accessToken = r.data.token
          s.tokenExpires = r.data.tokenExpires
          s = JSON.stringify(s)
          sessionStorage.setItem('session', s)
          commit('setSession', s)
        }
      })
    },
    logout ({ commit }) {
      sessionStorage.removeItem('session')
      commit('setState', availStates.PROVIDE_PERSONNUMBER)
      commit('setSession', null)
    },
    fakeLogin ({ commit }, data) {
      commit('setMessage', 'Inloggad som test.')
      commit('setState', availStates.SUCCESS)
      const s = JSON.stringify({
        accessToken: 'fake',
        refreshToken: 'fake',
        tokenExpires: '2221-01-01T00:00:00Z', // fun bug for future generations
        account: {
          sub: 'd239b763-3d88-495b-b4b9-216b2bd5f046',
          personalNumber: data.personNumber,
          name: 'Test Testsson',
          givenName: 'Test',
          surname: 'Testsson'
        }
      })
      sessionStorage.setItem('session', s)
      commit('setSession', s)
    },
    clear ({ commit }) {
      commit('resetState')
    }
  },
  beforeDestroy () {
    // TODO: Cancel current orderRef
    if (this.state.session.collector !== null) {
      clearInterval(this.state.session.collector)
      this.state.session.collector = null
    }
  },
  getters: {
    isLoggedIn: (state) => state.session != null,
    authStatus: (state) => state.authState,
    session: (state) =>
      state.session != null ? JSON.parse(state.session) : null,
    accessToken: (state) =>
      state.session != null ? JSON.parse(state.session).accessToken : null
  }
}
